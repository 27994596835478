import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { AppContext } from '../../context/appContext';
// import { LanguageContext } from '../../context/lngContext';

import 'react-datepicker/dist/react-datepicker.css';

import './ApplicationForm.css';
import { DatePickerField } from './DatePickerField';
import FileUploadField from './FileUploadField';
import DropdownSelect from './DropdownSelect';

const phoneRegExp = /^\+\d{2,3} ?\(?(\d{2})\)? ?(\d{2}) ?(\d{2}) ?(\d{3})$/;

export function ScrollToError() {
    const formik = useFormikContext();
    const submitting = formik?.isSubmitting;

    useEffect(() => {
        const el = document.querySelector('.with-error');
        (el?.parentElement ?? el)?.scrollIntoView();
    }, [submitting]);
    return null;
}

export default function ApplicationForm({ onSubmit, vacancy, isMobile }) {
    const { t } = useTranslation();
    const { contextItems } = useContext(AppContext);
    // const { languageContext } = useContext(LanguageContext);
    const [currentCountry, setCurrentCountry] = useState('');

    const initialValues = {
        firstName: '',
        lastName: '',
        birthDate: undefined,
        gender: '',
        country: '',
        city: '',
        address: '',
        houseNumber: '',
        floor: '',
        apartmentNumber: '',
        email: '',
        phone: '',
        confirm: false,
        confirmSendData: false,
        gdpr: false,
        cv: null,
        motivation: null,
    };

    useEffect(() => {
        if (!vacancy) return;
        const id = contextItems.searchItems.countryId || vacancy.country_id;
        const country = contextItems.countries.find((item) => item.country_id === id);
        const name = country?.country_name || country?.country_title;
        setCurrentCountry(name);
    }, [vacancy]);

    return (
        <>
            <div className="appliaction-form__sub-title">{t('form.youAreApplying')}</div>
            <div className="appliaction-form__title">{vacancy?.vacancy_title}</div>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={Yup.object().shape({
                    firstName: Yup.string().required(t('errors.firstName')),
                    lastName: Yup.string().required(t('errors.lastName')),
                    birthDate: Yup.date(t('errors.birth')).required(t('errors.birth')),
                    gender: Yup.string().required(t('errors.gender')),
                    country: Yup.string().required(t('errors.country')),
                    city: Yup.string().required(t('errors.city')),
                    address: Yup.string().required(t('errors.address')),
                    houseNumber: Yup.string().required(t('errors.house')),
                    floor: Yup.number(t('errors.floor')),
                    apartmentNumber: Yup.number(t('errors.apartment')),
                    email: Yup.string().email().required(t('errors.email')),
                    phone: Yup.string().matches(phoneRegExp, t('errors.phone')).required(t('errors.phone')),
                    confirm: Yup.bool().required(),
                    confirmSendData: Yup.bool().required(),
                    gdpr: Yup.bool().required(),
                })}
            >
                {({ values, errors, touched, handleSubmit, isSubmitting, handleChange, handleBlur, setFieldValue }) => (
                    <form className="application-form" onSubmit={handleSubmit}>
                        <ScrollToError />
                        <div className="appliaction-form__section-title">{t('form.personalInfo')}</div>
                        <div className="field-set">
                            <label htmlFor="firstName" className="form-input-label">
                                {t('form.firstName')}
                            </label>
                            <input
                                id="firstName"
                                className={`form-input ${errors.firstName && touched.firstName ? 'with-error' : ''}`}
                                type="text"
                                placeholder={t('form.firstName')}
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.firstName && touched.firstName && (
                                <div className="input-feedback">{errors.firstName}</div>
                            )}
                        </div>
                        <div className="field-set">
                            <label htmlFor="lastName" className="form-input-label">
                                {t('form.lastName')}
                            </label>
                            <input
                                id="lastName"
                                className={`form-input ${errors.lastName && touched.lastName ? 'with-error' : ''}`}
                                type="text"
                                placeholder={t('form.lastName')}
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.lastName && touched.lastName && (
                                <div className="input-feedback">{errors.lastName}</div>
                            )}
                        </div>
                        <div className="fields-row">
                            <div className="field-set">
                                <label htmlFor="birthDate" className="form-input-label">
                                    {t('form.birth')}
                                </label>
                                <DatePickerField
                                    id="birthDate"
                                    className={`form-input ${
                                        errors.birthDate && touched.birthDate ? 'with-error' : ''
                                    }`}
                                    name="birthDate"
                                    placeholderText="MM/DD/YYYY"
                                    isMobile={true}
                                />
                                {errors.birthDate && touched.birthDate && (
                                    <div className="input-feedback">{errors.birthDate}</div>
                                )}
                            </div>
                            <div className="field-set">
                                <label htmlFor="gender" className="form-input-label">
                                    {t('form.gender')}
                                </label>

                                <DropdownSelect
                                    className={`form-input ${errors.gender && touched.gender ? 'with-error' : ''}`}
                                    value={values.gender}
                                    onChange={(value) => setFieldValue('gender', value)}
                                    items={[t('form.male'), t('form.female')]}
                                    placeholder={t('form.selectGender')}
                                />
                                {errors.gender && touched.gender && (
                                    <div className="input-feedback">{errors.gender}</div>
                                )}
                            </div>
                        </div>

                        <div className="appliaction-form__section-title">{t('form.locationInfo')}</div>
                        <div className="field-set">
                            <label htmlFor="country" className="form-input-label">
                                {t('form.country')}
                            </label>
                            <input
                                id="country"
                                className={`form-input ${errors.country && touched.country ? 'with-error' : ''}`}
                                type="text"
                                placeholder={t('form.enterCountry')}
                                value={values.country}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.country && touched.country && (
                                <div className="input-feedback">{errors.country}</div>
                            )}
                        </div>
                        <div className="field-set">
                            <label htmlFor="city" className="form-input-label">
                                {t('form.city')}
                            </label>
                            <input
                                id="city"
                                className={`form-input ${errors.city && touched.city ? 'with-error' : ''}`}
                                type="text"
                                placeholder={t('form.enterCity')}
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.city && touched.city && <div className="input-feedback">{errors.city}</div>}
                        </div>
                        <div className="field-set">
                            <label htmlFor="address" className="form-input-label">
                                {t('form.address')}
                            </label>
                            <input
                                id="address"
                                className={`form-input ${errors.address && touched.address ? 'with-error' : ''}`}
                                type="text"
                                placeholder={t('form.enterAddress')}
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.address && touched.address && (
                                <div className="input-feedback">{errors.address}</div>
                            )}
                        </div>
                        <div className="fields-row">
                            <div className="field-set">
                                <label htmlFor="houseNumber" className="form-input-label">
                                    {t('form.houseNumber')}
                                </label>
                                <input
                                    id="houseNumber"
                                    className={`form-input ${
                                        errors.houseNumber && touched.houseNumber ? 'with-error' : ''
                                    }`}
                                    type="number"
                                    placeholder={`№ ${t('form.houseNumber')}`}
                                    value={values.houseNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    min={1}
                                />
                                {errors.houseNumber && touched.houseNumber && (
                                    <div className="input-feedback">{errors.houseNumber}</div>
                                )}
                            </div>
                            <div className="field-set">
                                <label htmlFor="floor" className="form-input-label">
                                    {t('form.floor')}
                                </label>
                                <input
                                    id="floor"
                                    className={`form-input ${errors.floor && touched.floor ? 'with-error' : ''}`}
                                    type="number"
                                    placeholder={`№ ${t('form.floor')}`}
                                    value={values.floor}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    min={1}
                                />
                                {errors.floor && touched.floor && <div className="input-feedback">{errors.floor}</div>}
                            </div>
                            <div className="field-set">
                                <label htmlFor="apartmentNumber" className="form-input-label">
                                    {t('form.apartment')}
                                </label>
                                <input
                                    id="apartmentNumber"
                                    className={`form-input ${
                                        errors.apartmentNumber && touched.apartmentNumber ? 'with-error' : ''
                                    }`}
                                    type="number"
                                    placeholder={`№ ${t('form.apartment')}`}
                                    value={values.apartmentNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    min={1}
                                />
                                {errors.apartmentNumber && touched.apartmentNumber && (
                                    <div className="input-feedback">{errors.apartmentNumber}</div>
                                )}
                            </div>
                        </div>

                        <div className="appliaction-form__section-title">{t('form.contactInfo')}</div>
                        <div className="field-set">
                            <label htmlFor="email" className="form-input-label">
                                {t('form.email')}
                            </label>
                            <input
                                id="email"
                                className={`form-input ${errors.email && touched.email ? 'with-error' : ''}`}
                                type="text"
                                placeholder={t('form.enterEmail')}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
                        </div>
                        <div className="field-set">
                            <label htmlFor="phone" className="form-input-label">
                                {t('form.phone')}
                            </label>
                            <input
                                id="phone"
                                className={`form-input ${errors.phone && touched.phone ? 'with-error' : ''}`}
                                type="text"
                                placeholder="+XX (XXX) XX XX XXX"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.phone && touched.phone && <div className="input-feedback">{errors.phone}</div>}
                        </div>

                        <div className="upload-section">
                            <div className="appliaction-form__section-title-small">{t('form.cv')}</div>
                            <div className="file-label">{t('form.addPhoto')}</div>
                            <FileUploadField name="cv" title="CV" />
                        </div>

                        <div className="upload-section">
                            <div className="appliaction-form__section-title-small">{t('form.motivation')}</div>
                            <div className="file-label">{t('form.addFileMotivation')}</div>
                            <FileUploadField name="motivation" title="Motivation letter" />
                        </div>

                        <div className="description-text">{t('form.herebyGrant')}</div>

                        <div className="checkbox-form-group">
                            <input type="checkbox" id="confirm" checked={values.confirm} onChange={handleChange} />
                            <label htmlFor="confirm">{t('form.evidence', { country: currentCountry })}</label>
                        </div>

                        <div className="checkbox-form-group">
                            <input
                                type="checkbox"
                                id="confirmSendData"
                                checked={values.confirmSendData}
                                onChange={handleChange}
                            />
                            <label htmlFor="confirmSendData">{t('form.forwarding', { country: currentCountry })}</label>
                        </div>

                        <div className="checkbox-form-group">
                            <input type="checkbox" id="gdpr" checked={values.gdpr} onChange={handleChange} />
                            <label htmlFor="gdpr">{t('form.gdpr')}</label>
                        </div>

                        <div className="appliaction-form__sub-title">{t('form.bySubmitting')}</div>

                        <div className="divider" />

                        <button
                            className="submit-button"
                            type="submit"
                            disabled={isSubmitting || !values.confirm || !values.confirmSendData || !values.gdpr}
                        >
                            {t('form.toSend')}
                        </button>
                    </form>
                )}
            </Formik>
        </>
    );
}
