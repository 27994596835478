import './Overlay.css';
import { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../context/appContext';
import arrowDown from '../../assets/arrowdown.svg';
import arrowUpGreen from '../../assets/arrow-up-green.svg';
import filterClose from '../../assets/filter-close.svg';

import FilterGroupCategories from './FilterGroupCategories';
import FilterGroupJobTypes from './FilterGroupJobTypes';

export default function Overlay({ countries, categories }) {
    const { t } = useTranslation();
    const { contextItems, setContextItems } = useContext(AppContext);
    const currentStore = useRef({});

    const [shownFilterGroups, setShownFilterGroups] = useState({});

    const selectedCountryId = contextItems.searchItems.countryId;

    useEffect(() => {
        currentStore.current = contextItems;
    }, []);

    const setCategoryGroups = () => {
        let defaultCategories = categories;
        if (selectedCountryId) {
            const selectedCountry = countries.find((item) => item.country_id === selectedCountryId);
            // todo use sortCategories here (move sortCategories to utils)
            defaultCategories = selectedCountry.categories_group.sort((a, b) => {
                if (a.categories_group_id > b.categories_group_id) {
                    return 1;
                }
                if (a.categories_group_id < b.categories_group_id) {
                    return -1;
                }
                return 0;
            });
            return defaultCategories;
        }
    };

    const setJobTypes = () => {
        const countJobsByType = (type) => {
            let jobAmount = 0;

            if (selectedCountryId) {
                const selectedCountry = countries.find((item) => item.country_id === selectedCountryId);
                jobAmount = selectedCountry?.job_type[type];
            } else {
                categories.forEach((item) => (jobAmount += item.job_type[type]));
            }

            return jobAmount;
        };
        // TO-DO: це оптимізувати (можливо винести в конфіг)
        const newJobTypes = [
            {
                id: 1,
                name: 'part_time',
                title: t('overlay.partTime'),
                jobsAmount: countJobsByType('part_time'),
            },
            {
                id: 2,
                name: 'full_time',
                title: t('overlay.fullTime'),
                jobsAmount: countJobsByType('full_time'),
            },
        ];

        return newJobTypes;
    };

    const filterCloseClick = () => {
        setContextItems({ ...currentStore.current, showOverlay: false });
    };

    const filterGroupClickHandler = (filterGroup) => {
        setShownFilterGroups({ ...shownFilterGroups, [filterGroup]: !shownFilterGroups[filterGroup] });
    };

    const applyClickHandler = () => {
        // TO-DO: в т.ч. закривати деталі вакансії

        setContextItems({
            ...contextItems,
            vacancies: [],
            page: 1,
            isFetching: true,
            showOverlay: false,
        });
    };

    const clearHandler = () => {
        setContextItems({
            ...contextItems,
            searchItems: {
                ...contextItems.searchItems,
                categories: [],
                job_types: [],
            },
        });
    };

    return (
        <div className="overlay-wrapper">
            <div className="overlay-background"></div>
            <div className="filter-categories-block">
                <div className="filter-items-wrapper">
                    <div className="filter-title-wrapper">
                        <p className="filter-title">{t('header.filter')}</p>
                        <img
                            className="filter-close-image"
                            src={filterClose}
                            alt="filter-close"
                            onClick={filterCloseClick}
                        />
                    </div>

                    {/* TO-DO: це теж винести в компонент або перебирати map-ом, категорій фільтрів може більше бути */}
                    <div className="filter-group-wrapper">
                        <div
                            className="filter-group-title-wrapper"
                            onClick={() => filterGroupClickHandler('categories')}
                        >
                            <p className="filter-group-title">{t('header.jobCategories')}</p>
                            <img src={shownFilterGroups.categories ? arrowUpGreen : arrowDown} alt="arrow" />
                        </div>
                        {shownFilterGroups.categories && <FilterGroupCategories categoryGroups={setCategoryGroups()} />}
                    </div>
                    <div className="filter-group-wrapper">
                        <div className="filter-group-title-wrapper" onClick={() => filterGroupClickHandler('types')}>
                            <p className="filter-group-title">{t('header.jobType')}</p>
                            <img src={shownFilterGroups.types ? arrowUpGreen : arrowDown} alt="arrow" />
                        </div>
                        {shownFilterGroups.types && <FilterGroupJobTypes jobTypes={setJobTypes()} />}
                    </div>
                </div>

                <div className="filter-buttons">
                    <button className="button mr-12 clear-button button-full-with" onClick={clearHandler}>
                        {t('header.clear')}
                    </button>
                    <button className="button-primary apply-button button-full-with" onClick={applyClickHandler}>
                        {t('header.apply')}
                    </button>
                </div>
            </div>
        </div>
    );
}
