import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Header.css';
import { AppContext } from '../../context/appContext';
import logo from '../../assets/logo.svg';
import uk from '../../assets/flags/uk.svg';
import ukblack from '../../assets/flags/uk-black.svg';
import ukraine from '../../assets/flags/ukraine.svg';
import ukraineblack from '../../assets/flags/ukraine-black.svg';
import { LanguageContext } from '../../context/lngContext';

export default function Header({ absolute }) {
    const { i18n } = useTranslation();
    const { languageContext, setLanguageContext } = useContext(LanguageContext);
    const { contextItems, setContextItems } = useContext(AppContext);
    const navigate = useNavigate();
    const [ukactive, setUkactive] = useState(true);
    const [ukraineactive, setUkraineactive] = useState(false);

    useEffect(() => {
        setUkactive(languageContext.lang === 'en' ? true : false);
        setUkraineactive(languageContext.lang === 'en' ? false : true);
    }, []);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguageContext({
            lang: lng,
        });
        setUkactive(lng === 'en' ? true : false);
        setUkraineactive(lng === 'en' ? false : true);
    };

    const goHome = () => {
        setContextItems({
            ...contextItems,
            vacancies: [],
            showGreeting: true,
            searchItems: { categories: [], countryId: null, job_types: [] },
        });

        navigate('/');
    };

    return (
        <div className={`main-header ${absolute ? 'absolute' : ''}`}>
            <img
                className="company-logo"
                src={logo}
                alt={'logo'}
                width="212px"
                style={{ cursor: 'pointer' }}
                onClick={goHome}
            />
            <div className="lang-switch">
                <img
                    src={ukactive ? ukblack : uk}
                    alt={'English'}
                    onClick={() => changeLanguage('en')}
                    style={{ cursor: 'pointer' }}
                />
                <span className="devider">|</span>
                <img
                    src={ukraineactive ? ukraineblack : ukraine}
                    alt={'Ukrainian'}
                    onClick={() => changeLanguage('uk')}
                    style={{ cursor: 'pointer' }}
                />
            </div>
        </div>
    );
}
