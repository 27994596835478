import './Footer.css';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/appContext';

import logo from '../../assets/logo.svg';
import twitter from '../../assets/twitter.png';
import fb from '../../assets/facebook.svg';
import linkedin from '../../assets/linkedin.svg';
import { useTranslation } from 'react-i18next';

export default function Footer({ absolute }) {
    const { t } = useTranslation();
    const { contextItems, setContextItems } = useContext(AppContext);
    const navigate = useNavigate();

    const goHome = () => {
        setContextItems({
            ...contextItems,
            vacancies: [],
            showGreeting: true,
            searchItems: { categories: [], countryId: null, job_types: [] },
        });

        navigate('/');
    };

    return (
        <div className={`footer-wrapper ${absolute ? 'absolute' : ''}`}>
            <div className="footer-content">
                <img
                    className="footer-logo"
                    src={logo}
                    alt={'logo'}
                    width="212px"
                    style={{ cursor: 'pointer' }}
                    onClick={goHome}
                ></img>
                <div className="footer-links">
                    <a
                        href="https://spar-international.com/people-careers/your-career-at-spar-a-world-of-opportunities/joining-spar-international/"
                        target="blank"
                    >
                        <p className="footer-link">{t('footer.joining')}</p>
                    </a>
                    <a
                        href="https://spar-international.com/people-careers/your-career-at-spar-a-world-of-opportunities/"
                        target="blank"
                    >
                        <p className="footer-link">{t('footer.career')}</p>
                    </a>
                    <p
                        className="footer-link"
                        onClick={() => {
                            navigate('/terms-conditions');
                        }}
                    >
                        {t('footer.terms')}
                    </p>
                </div>
                <div className="footer-social-media">
                    <a href="https://twitter.com/SPARInt" target="blank">
                        <img className="socialm-link" src={twitter} alt="twitter" width="22px" />
                    </a>
                    <a href="https://www.facebook.com/sparinternational" target="blank">
                        <img className="socialm-link" src={fb} alt="facebook" width="22px" />
                    </a>
                    <a href="https://nl.linkedin.com/company/spar-international" target="blank">
                        <img className="socialm-link" src={linkedin} alt="linkedin" width="22px" />
                    </a>
                </div>
            </div>
        </div>
    );
}
