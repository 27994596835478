import React, { useRef } from 'react';
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";

import './DatePickerField.css';
import calendar from '../../assets/calendar.svg';

export const DatePickerField = ({ placeholderText, isMobile, ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const calendarRef = useRef();
    const calendarNativeRef = useRef();

    const openDatepicker = () => {
        calendarRef.current?.setOpen(true);
    };

    return (
        <div className="date-picker-wrap">
            {!isMobile && <>
                <DatePicker
                    ref={calendarRef}
                    {...field}
                    {...props}
                    placeholderText={placeholderText}
                    selected={(field.value && new Date(field.value)) || null}
                    onChange={val => {
                        if (val) {
                            setFieldValue(field.name, val);
                        }
                    }}
                    withPortal={isMobile}
                />
                <img src={calendar} onClick={openDatepicker} alt="calendar"/>
            </>}

            {isMobile &&
                <div className={`datepicker-toggle ${props.className}`}>
                    {reformatDate(field.value)}
                    {!field.value && (<span className="placeholder">{placeholderText}</span>)}
                    <img src={calendar} className="datepicker-toggle-button" alt="calendar"/>
                    <input
                        ref={calendarNativeRef}
                        type="date"
                        className="datepicker-input"
                        value={field.value || ''}
                        onChange={e => {
                            if (e?.target?.value) {
                                setFieldValue(field.name, e.target.value);
                            }
                        }}
                    />
                </div>
            }
        </div>
    );
};

function reformatDate(dateStr) {
    if (!dateStr) return '';
    const dArr = dateStr.split("-");  // ex input "2010-01-18"
    return dArr[2]+ "/" +dArr[1]+ "/" +dArr[0].substring(2); //ex out: "18/01/10"
}
