import './Categories.css';

import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../context/appContext';

import checkedItem from '../../assets/checked.svg';

export default function Categories({ categories }) {
    const [chosenCategories, setChosenCategories] = useState({});

    const { contextItems, setContextItems } = useContext(AppContext);

    const setIDes = () => {
        // тут сетяться всі категорії спочатку в false (тобто вони не зачекані)
        // а потім залежно від того, що в контексті потрібні сетяться в true
        let ids = {};
        categories.forEach((item) => {
            ids = { ...ids, [item.category_id]: false };
        });
        // TO-DO: можливо тут теж не треба сетити в фалс
        contextItems.searchItems.categories.forEach((item) => {
            if (Object.keys(ids).includes(item.toString())) {
                ids[item.toString()] = true;
            }
        });

        return ids;
    };

    useEffect(() => {
        setChosenCategories(setIDes());
    }, [contextItems.searchItems.categories]);

    const categoryChooseHandler = (categoryID) => {
        // TO-DO: подумати, як скидувати групу категорій без скидання категорій
        // let deselectOne;
        // if (chosenCategories[categoryID]) deselectOne = true;

        setChosenCategories({ ...chosenCategories, [categoryID]: !chosenCategories[categoryID] });

        const rawArray = [...contextItems.searchItems.categories];
        const index = rawArray.indexOf(categoryID);

        if (index > -1) {
            rawArray.splice(index, 1);
        } else {
            rawArray.push(categoryID);
        }

        setContextItems({
            ...contextItems,
            searchItems: {
                ...contextItems.searchItems,
                categories: rawArray,
            },
        });
    };

    const renderItems = categories.map((item) => {
        const titleClasses = chosenCategories[item.category_id]
            ? 'subcategory-filter-item-wrapper checked'
            : 'subcategory-filter-item-wrapper';
        return (
            <div
                key={item.category_id}
                className={titleClasses}
                onClick={() => categoryChooseHandler(item.category_id)}
            >
                <div className="filter-checkbox-checker">
                    {chosenCategories[item.category_id] && (
                        <img className="subcategory-checked" src={checkedItem} alt="checked" />
                    )}
                </div>
                <span className="subcategory-item-title">
                    {item.category_title} <span className="subcategory-jobs-number">{`(${item.vacancies_count})`}</span>
                </span>
            </div>
        );
    });

    return <div className="subcategory-items-wrapper">{renderItems}</div>;
}
