import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './JobOverview.css';
import JobDetailsList from './JobDetailsList';
import JobInfo from './JobInfo';

export default function JobOverview({ job, isMobile }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const applyJob = () => {
        if (job.apply_vacancy_link) {
            window.location.href = `${job.apply_vacancy_link}`;
        } else {
            navigate(`/search-job/${job.vacancy_id}/apply`);
        }
    };

    const goToDetailsPage = () => {
        navigate(`/job-details/${job.vacancy_id}`);
    };

    return (
        <div className="job-overview-wrapper">
            <div className="details-wrapper">
                <div className="details-list-wrapper">
                    <JobDetailsList detailsTitle={t('main.yourTasks')} details={job.activities} />
                    <JobDetailsList detailsTitle={t('main.yourProfile')} details={job.requirements} />
                </div>
                <div className="details-buttons-wrapper">
                    <button className="button button-full-with" onClick={goToDetailsPage}>
                        {t('header.moreDetails')}
                    </button>
                    <button className="button-primary button-full-with" onClick={applyJob}>
                        {t('header.applyNow')}
                    </button>
                </div>
            </div>
            {!isMobile && (
                <div className="job-info-wrapper">
                    <JobInfo job={job} />
                </div>
            )}
        </div>
    );
}
