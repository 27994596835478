import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import './FileUploadField.css';
import { humanFileSize } from './utils';
import fileLoaded from '../../assets/fileLoaded.svg';
import Loader from '../../assets/Loader.svg';
import FileError from '../../assets/FileError.svg';
import paper from '../../assets/paper.svg';
import filterClose from '../../assets/filter-close.svg';

export default function FileUploadField({ ...props }) {
    const { t } = useTranslation();
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(0);
    const [totalSize, setTotalSize] = useState(0);
    const [error, setError] = useState(false);

    const reader = new FileReader();

    reader.addEventListener('loadstart', (event) => {
        setTotalSize(event.total);
        setLoading(true);
    });

    reader.addEventListener('progress', (event) => {
        setLoaded(event.loaded);
    });

    reader.addEventListener('error', () => {
        setError(true);
    });

    reader.addEventListener('loadend', () => {
        setLoading(false);
    });

    const onClear = () => {
        setFieldValue(props.name, null);
        setLoading(false);
        reader.abort();
    };

    return (
        <>
            {!field.value && !error && !loading && <label htmlFor="cv" className="custom-file-upload">
                <img className="upload-img" src={paper} alt="Add file"/>{t('form.addFile')}
                <input
                    id="cv"
                    type="file"
                    name="cv"
                    onChange={(event) => {
                        reader.readAsDataURL(event.target.files[0]);
                        setFieldValue(props.name, event.target.files[0]);
                    }}
                />
            </label>}

            {loading && !error && (<div className="file-info-wrap">
                <img src={Loader} alt={field.value?.name} />
                <div className="file-info-description">
                    <div className="file-info-name">{props.title}</div>
                    <div className="file-info-size">{humanFileSize(loaded)}/{humanFileSize(totalSize)}</div>
                </div>
            </div>)}

            {error && (<div className="file-info-wrap">
                <img src={FileError} alt={field.value?.name} />
                <div className="file-info-description">
                    <div className="file-info-name">{props.title}</div>
                    <div className="file-info-size">{humanFileSize(loaded)}/{humanFileSize(totalSize)}</div>
                </div>
            </div>)}

            {!loading && !error && field.value && (<div className="file-info-wrap">
                <div className="file-info">
                    <img src={fileLoaded} alt={field.value?.name} />
                    <div className="file-info-description">
                        <div className="file-info-name">{props.title}</div>
                        <div className="file-info-size">{humanFileSize(field.value.size)}</div>
                    </div>
                </div>
                <img
                    className="filter-close-image"
                    src={filterClose}
                    alt="filter-close"
                    onClick={onClear}
                />
            </div>)}
        </>
    );
}
