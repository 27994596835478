import georgia from '../../assets/flags/georgia.svg';
import albania from '../../assets/flags/albania.svg';
import belgium from '../../assets/flags/belgium.svg';
import kosovo from '../../assets/flags/kosovo.svg';
import croatia from '../../assets/flags/croatia.svg';
import denmark from '../../assets/flags/denmark.svg';
import switzerland from '../../assets/flags/switzerland.svg';
import italy from '../../assets/flags/italy.svg';
import poland from '../../assets/flags/poland.svg';
import norway from '../../assets/flags/norway.svg';
import austria from '../../assets/flags/austria.svg';
import hungary from '../../assets/flags/hungary.svg';
import slovenia from '../../assets/flags/slovenia.svg';
import netherlands from '../../assets/flags/netherlands.svg';
import spain from '../../assets/flags/spain.svg';
import malta from '../../assets/flags/malta.svg';
import ireland from '../../assets/flags/ireland.svg';
import scotland from '../../assets/flags/scotland.svg';
import nireland from '../../assets/flags/nireland.svg';
import england from '../../assets/flags/england.svg';

const flags = [
    {
        selectorId: 'albania',
        countryId: 1,
        src: albania,
    },
    {
        selectorId: 'austria',
        countryId: 2,
        src: austria,
    },
    {
        selectorId: 'belgium',
        countryId: 26,
        src: belgium,
    },
    {
        selectorId: 'croatia',
        countryId: 4,
        src: croatia,
    },
    {
        selectorId: 'denmark',
        countryId: 5,
        src: denmark,
    },
    {
        selectorId: 'georgia',
        countryId: 6,
        src: georgia,
    },
    {
        selectorId: 'hungary',
        countryId: 7,
        src: hungary,
    },
    {
        selectorId: 'ireland',
        countryId: 8,
        src: ireland,
    },
    {
        selectorId: 'italy',
        countryId: 25,
        src: italy,
    },
    {
        selectorId: 'kosovo',
        countryId: 10,
        src: kosovo,
    },
    {
        selectorId: 'malta',
        countryId: 11,
        src: malta,
    },
    {
        selectorId: 'netherlands',
        countryId: 12,
        src: netherlands,
    },
    {
        selectorId: 'norway',
        countryId: 13,
        src: norway,
    },
    {
        selectorId: 'poland',
        countryId: 14,
        src: poland,
    },
    {
        selectorId: 'slovenia',
        countryId: 15,
        src: slovenia,
    },
    {
        selectorId: 'spain',
        countryId: 16,
        src: spain,
    },
    {
        selectorId: 'grancanaria',
        countryId: 17,
        src: spain,
    },
    {
        selectorId: 'switzerland',
        countryId: 18,
        src: switzerland,
    },
    {
        selectorId: 'scotland',
        countryId: 19,
        src: scotland,
    },
    {
        selectorId: 'nireland',
        countryId: 20,
        src: nireland,
    },
    {
        selectorId: 'nengland',
        countryId: 21,
        src: england,
    },
    {
        selectorId: 'blakemore',
        countryId: 22,
        src: england,
    },
    {
        selectorId: 'uk',
        countryId: 27,
        src: england,
    },
    // 23: Belgium Lambrecht
    // 24: Italy Maiora
    // 25: Italy
    // 26: Belgium
    // 27: UK
    // 3: Belgium C
    // 9: Italy A
];

export default flags;
