import './JobDetailsList.css';

export default function JobDetailsList({ detailsTitle, details }) {
    return (
        <div className="job-details-item">
            <div className="details-title">{detailsTitle}</div>
            <ul className="details-list">
                {details.map((detail, index) => (
                    <li key={index} className="details-item">
                        {detail}
                    </li>
                ))}
            </ul>
        </div>
    );
}
