import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import './JobDetails.css';
import DetailsImage from '../../assets/details-hero.png';
import DetailsImageMobile from '../../assets/detailsMobile.png';
import { AppContext } from '../../context/appContext';
import Footer from '../footer/Footer';
import JobInfo from '../jobslist/JobInfo';
import link from '../../assets/link.svg';
import print from '../../assets/print.svg';
import Header from '../header/Header';
import { LanguageContext } from '../../context/lngContext';

/**
 * @return {null}
 */
export default function JobDetails() {
    const { t } = useTranslation();
    const { languageContext } = useContext(LanguageContext);
    const { contextItems, setContextItems } = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams() || 0;
    const [vacancy, setVacancy] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    const getJob = useCallback(
        (id) => {
            axios
                .get(`https://spar.jobs:8080/api/get/vacancy`, {
                    // .get(`https://eval.spar.jobs:8080/api/get/vacancy`, {
                    params: {
                        vacancy_id: id,
                        lang: languageContext.lang || 'en',
                    },
                })
                .then((response) => {
                    setVacancy(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    navigate('/error-page');
                });
        },
        [id, languageContext.lang]
    );

    useEffect(() => {
        getJob(id);
    }, [id, languageContext.lang]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const renderVacancyDetails = (details) => {
        if (!vacancy?.vacancy_id) return;

        return vacancy[details].map((item, index) => (
            <div key={index} className="details-item-content-wrapper">
                <div className="point"></div>
                <p className="details-list-item">{item}</p>
            </div>
        ));

        // if (!contextItems.vacancy?.[details]) return;
        // return contextItems.vacancy?.[details].map((item, index) => (
        //     <div key={index} className="details-item-content-wrapper">
        //         <div className="point"></div>
        //         <p className="details-list-item">{item}</p>
        //     </div>
        // ));
    };

    const goBack = (dest) => {
        setContextItems({
            ...contextItems,
            searchItems:
                dest === 'home' ? { categories: [], countryId: null, job_types: [] } : contextItems.searchItems,
            vacancies: dest === 'home' ? [] : contextItems.vacancies,
            showGreeting: dest === 'home' ? true : false,
        });

        navigate('/');
    };

    const applyJob = () => {
        if (vacancy.apply_vacancy_link) {
            window.location.href = `${vacancy.apply_vacancy_link}`;
        } else {
            navigate(`/search-job/${vacancy?.vacancy_id}/apply`);
        }
    };

    const copyLink = () => {
        console.log('URL: ', `https://eval.spar.jobs/${location.pathname}`);
        navigator.clipboard.writeText(`https://eval.spar.jobs${location.pathname}`);
    };

    if (!vacancy?.vacancy_id) {
        return null;
    }

    return (
        <div className="details-page-wrapper">
            <div className="details-page-block">
                <Header />
                <div className="details-container">
                    <div className="bread-crumbs-wrapper">
                        <span onClick={() => goBack('home')}>{t('header.home')}</span>
                        <span>{'>'}</span>
                        <span onClick={() => goBack('jobs')}>{t('header.searchJob')}</span>
                        <span>{'>'}</span>
                        <span>{vacancy?.vacancy_title}</span>
                    </div>
                    <div className="hero-image-wrapper">
                        <img
                            className="details-image"
                            src={isMobile ? DetailsImageMobile : DetailsImage}
                            alt={'details'}
                        />
                    </div>
                    <div className="details-content-wrapper">
                        <div className="details-content">
                            <h1 className="vacancy-title">{vacancy?.vacancy_title}</h1>

                            {isMobile && (
                                <div className="details-info-wrapper">
                                    <div className="details-info">
                                        <JobInfo job={vacancy} />
                                    </div>
                                </div>
                            )}

                            <p className="details-item-title">{t('main.general')}</p>
                            <p className="details-item-content">{t('jobDetails.sparSummary')}</p>
                            <p className="details-item-content">{t('jobDetails.sparSummary1')}</p>

                            <p className="details-item-title">{t('main.yourTasks')}</p>
                            <div className="details-items-container">{renderVacancyDetails('activities')}</div>

                            <p className="details-item-title">{t('main.yourProfile')}</p>
                            <div className="details-items-container">{renderVacancyDetails('requirements')}</div>

                            <p className="details-item-title">{t('main.ourOffer')}</p>
                            <div className="details-items-container">{renderVacancyDetails('offers')}</div>

                            <p className="details-item-title">{t('main.contact')}</p>
                            <p className="details-item-content">{vacancy.contacts}</p>
                        </div>
                        {!isMobile && (
                            <div className="details-info-wrapper">
                                <div className="details-info">
                                    <JobInfo job={vacancy} />
                                    <button className="button-primary apply-job-button-m" onClick={applyJob}>
                                        {t('header.applyNow')}
                                    </button>
                                </div>
                                <div className="link-actions-wrapper">
                                    <div className="action-button mr-5" onClick={copyLink}>
                                        <img src={link} alt="link" />
                                        <span className="button-title">{t('main.copyLink')}</span>
                                    </div>
                                    <div className="action-button" onClick={window.print}>
                                        <img src={print} alt="print" />
                                        <span className="button-title">{t('main.print')}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isMobile && (
                            <div className="buttons-wrapper">
                                <div className="link-actions-wrapper">
                                    <div className="action-button mr-5" onClick={copyLink}>
                                        <img src={link} alt="link" />
                                        <span className="button-title">{t('main.copyLink')}</span>
                                    </div>
                                    <div className="action-button" onClick={window.print}>
                                        <img src={print} alt="print" />
                                        <span className="button-title">{t('main.print')}</span>
                                    </div>
                                </div>
                                <button className="button-primary apply-job-button-m" onClick={applyJob}>
                                    {t('header.applyNow')}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}
