import './Categories.css';
import './FilterGroups.css';

import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../context/appContext';

import checkedItem from '../../assets/checked.svg';

export default function Categories({ categories }) {
    const [chosenCategories, setChosenCategories] = useState({});
    const { contextItems, setContextItems } = useContext(AppContext);

    const setIDes = () => {
        let ids = {};
        categories.forEach((item) => {
            ids = { ...ids, [item.category_id]: false };
        });
        // TO-DO: move to utils?
        contextItems.searchItems.categories.forEach((item) => {
            if (Object.keys(ids).includes(item.toString())) {
                ids[item] = true;
            }
        });
        return ids;
    };

    useEffect(() => {
        setChosenCategories(setIDes());
    }, [contextItems.searchItems.categories]);

    const categoryChooseHandler = (categoryID) => {
        setChosenCategories({ ...chosenCategories, [categoryID]: !chosenCategories[categoryID] });

        const rawArray = [...contextItems.searchItems.categories];
        const index = rawArray.indexOf(categoryID);
        if (index > -1) {
            rawArray.splice(index, 1);
        } else {
            rawArray.push(categoryID);
        }

        setContextItems({
            ...contextItems,
            searchItems: {
                ...contextItems.searchItems,
                categories: rawArray,
            },
        });
    };

    const renderItems = categories.map((item) => {
        // console.log('TRUE? ', chosenCategories);
        let checkboxCheckedClass = chosenCategories[item.category_id]
            ? 'checkbox-checker checkbox-checker-c'
            : 'checkbox-checker';

        return (
            <div
                key={item.category_id}
                className="subcategory-item-wrapper"
                onClick={() => categoryChooseHandler(item.category_id)}
            >
                <div className={checkboxCheckedClass}>
                    {chosenCategories[item.category_id] && (
                        <img className="subcategory-checked" src={checkedItem} alt="checked" />
                    )}
                </div>
                <span className="subcategory-item-title">{item.category_title}</span>
                <span className="subcategory-jobs-number">{`(${item.vacancies_count})`}</span>
            </div>
        );
    });
    return <div className="subcategory-items-wrapper">{renderItems}</div>;
}
