import './TextBlock.css';

export default function TextBlock({ text1, text2 }) {
    return (
        <>
            <p className="text">{text1}</p>
            <p className="text">{text2}</p>
        </>
    );
}
