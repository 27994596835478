import './Map.css';
import { useEffect, useRef, useContext, useState } from 'react';
import { AppContext } from '../../context/appContext';

import flags from './flags';

import { ReactComponent as MapSVG } from '../../assets/map.svg';
// import { ReactComponent as MapNewSVG } from '../../assets/mapnew.svg';
import zoomIn from '../../assets/zoom-in.svg';
import zoomOut from '../../assets/zoom-out.svg';
import earth from '../../assets/earth.svg';
import scotland from '../../assets/flags/scotland.svg';
import nireland from '../../assets/flags/nireland.svg';
import england from '../../assets/flags/england.svg';

import { useTranslation } from 'react-i18next';
import useWindowSize from '../../hooks/useWindowSize';

const colors = {
    default: '#CACACA',
    hovered: '#99BB9D',
    selected: '#34773B',
};

export default function Map({ countries }) {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    const [forceRefresh, setForceRefresh] = useState(false);
    const [hoveredCountry, setHoveredCountry] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const { contextItems, setContextItems } = useContext(AppContext);
    const { searchItems } = contextItems;

    const selectedCountryRef = useRef(null);
    const scale = useRef(1);

    const mapRef = useRef(null);
    const hoveredTooltipRef = useRef(null);
    const selectedTooltipRef = useRef(null);

    const isMobile = width <= 768;

    const onZoomClick = (action) => {
        switch (action) {
            case 'zoom-in':
                if (scale.current < 2) {
                    scale.current = 2;
                    mapRef.current.style.transform = 'scale(2)';
                    if (isMobile) {
                        mapRef.current.style.marginLeft = '40%';
                    }
                }
                break;
            case 'zoom-out':
                scale.current = 1;
                mapRef.current.style.transform = 'scale(1)';
                mapRef.current.style.marginLeft = '0';
                onZoomOut();
                break;
            default:
                break;
        }
    };

    const onZoomOut = () => {
        if (!selectedCountry) {
            return;
        }

        if (selectedCountryRef.current?.selectorId === 'uk') {
            ['scotland', 'nireland', 'nengland', 'blakemore'].forEach((item) => {
                document.getElementById(item).style.fill = colors.default;
            });
        }

        const selectedCountryElem = document.getElementById(selectedCountry.selectorId);
        selectedCountryElem.style.fill = colors.default;
        selectedCountryRef.current = null;
        setSelectedCountry(null);

        // Update in context
        setContextItems({
            ...contextItems,
            searchItems: {
                ...contextItems.searchItems,
                countryId: null,
            },
        });
    };

    const getCountryData = (countryId) => {
        return countries.find((item) => item.country_id === countryId);
    };

    const getCountryCenter = (countryElem) => {
        const countryPos = countryElem.getBoundingClientRect();

        return {
            top: countryPos.top + countryPos.height / 2,
            left: countryPos.left + countryPos.width / 2,
        };
    };

    const getMapOffset = (countryElem, scaleFactor) => {
        const mapPos = mapRef.current.getBoundingClientRect();
        const mapCenter = { top: mapPos.top + mapPos.height / 2, left: mapPos.left + mapPos.width / 2 };
        const countryCenter = getCountryCenter(countryElem);

        return {
            left: (mapCenter.left - countryCenter.left) / scaleFactor,
            top: (mapCenter.top - countryCenter.top) / scaleFactor,
        };
    };

    const onMapClick = (event) => {
        const clickOnMap = event.target.id === 'map';
        if (clickOnMap) {
            if (selectedCountryRef.current?.selectorId === 'uk') {
                ['scotland', 'nireland', 'nengland', 'blakemore'].forEach((item) => {
                    document.getElementById(item).style.fill = colors.default;
                });
            }

            scale.current = 1;
            mapRef.current.style.transform = 'scale(1) translate(0, 0)';
            mapRef.current.style.marginLeft = '0';
            setSelectedCountry(null);
            selectedCountryRef.current = null;
            onZoomOut();
        }
    };

    const handleCountrySelection = (selectorId, skipContextUpdate) => {
        const country = flags.find((item) => item.selectorId === selectorId);
        if (country?.countryId === selectedCountryRef.current?.countryId) {
            return;
        }

        if (selectorId === 'uk') {
            ['scotland', 'nireland', 'nengland', 'blakemore'].forEach((item) => {
                const countryElem = document.getElementById(item);
                const mapOffset = getMapOffset(countryElem, scale.current);

                scale.current = 2;
                mapRef.current.style.transform = `scale(2) translate(${mapOffset.left}px, ${mapOffset.top}px)`;
                mapRef.current.style.marginLeft = '0';

                countryElem.style.fill = colors.selected;

                flags.forEach((item) => {
                    if (!['scotland', 'nireland', 'nengland', 'blakemore'].includes(item.selectorId)) {
                        document.getElementById(item.selectorId).style.fill = colors.default;
                    }
                });
            });
        } else {
            const countryElem = document.getElementById(country.selectorId);
            const mapOffset = getMapOffset(countryElem, scale.current);

            scale.current = 2;
            mapRef.current.style.transform = `scale(2) translate(${mapOffset.left}px, ${mapOffset.top}px)`;
            mapRef.current.style.marginLeft = '0';

            countryElem.style.fill = colors.selected;
            selectedCountryRef.current = country;
            setSelectedCountry(country);

            flags.forEach((item) => {
                if (item.countryId !== country.countryId) {
                    document.getElementById(item.selectorId).style.fill = colors.default;
                }
            });

            if (!skipContextUpdate) {
                setContextItems({
                    ...contextItems,
                    searchItems: {
                        ...contextItems.searchItems,
                        countryId: country.countryId,
                    },
                });
            }
        }

        // Update in context
    };

    const onCountryClick = (event) => {
        if (event.target.id === 'uk') {
            return;
        }
        handleCountrySelection(event.target.id);
    };

    const onCountryOver = (event) => {
        if (event.target.id === 'uk') {
            return;
        }

        const country = flags.find((item) => item.selectorId === event.target.id);
        if (country?.countryId === selectedCountryRef.current?.countryId) {
            return;
        }

        const countryElem = document.getElementById(event.target.id);
        countryElem.style.fill = colors.hovered;
        setHoveredCountry(country);
    };

    const onCountryOut = (event) => {
        const country = flags.find((item) => item.selectorId === event.target.id);

        if (country?.countryId === selectedCountryRef.current?.countryId) {
            return;
        }

        const countryElem = document.getElementById(event.target.id);

        if (selectedCountryRef.current?.countryId === 27) {
            if (!['scotland', 'nireland', 'nengland', 'blakemore'].includes(event.target.id)) {
                countryElem.style.fill = colors.default;
            } else {
                countryElem.style.fill = colors.selected;
            }
        } else {
            countryElem.style.fill = colors.default;
        }

        setHoveredCountry(null);
    };

    const onTransitionEnd = () => {
        setForceRefresh(!forceRefresh);
    };

    const countryTooltipVariant = () => {
        let content;
        if (selectedCountry?.countryId === 27) {
            content = (
                <>
                    <CountryTooltip
                        country={{
                            selectorId: 'scotland',
                            countryId: 19,
                            src: scotland,
                        }}
                        getCountryData={getCountryData}
                        tooltipRef={selectedTooltipRef}
                    />
                    <CountryTooltip
                        country={{
                            selectorId: 'nireland',
                            countryId: 20,
                            src: nireland,
                        }}
                        getCountryData={getCountryData}
                        tooltipRef={selectedTooltipRef}
                    />
                    <CountryTooltip
                        country={{
                            selectorId: 'nengland',
                            countryId: 21,
                            src: england,
                        }}
                        getCountryData={getCountryData}
                        tooltipRef={selectedTooltipRef}
                    />
                    <CountryTooltip
                        country={{
                            selectorId: 'blakemore',
                            countryId: 22,
                            src: england,
                        }}
                        getCountryData={getCountryData}
                        tooltipRef={selectedTooltipRef}
                    />
                </>
            );
        } else {
            content = (
                <CountryTooltip
                    country={selectedCountry}
                    getCountryData={getCountryData}
                    tooltipRef={selectedTooltipRef}
                />
            );
        }
        return content;
    };

    useEffect(() => {
        let newCountryId = searchItems.countryId;
        let skipContextUpdate = false;

        switch (newCountryId) {
            case 3:
            case 23:
                newCountryId = 26;
                skipContextUpdate = true;
                break;
            case 9:
            case 24:
                newCountryId = 25;
                skipContextUpdate = true;
                break;
            default:
                break;
        }

        let country = flags.find((item) => item.countryId === newCountryId);

        if (country) {
            // handleCountrySelection(newCountryId === 27 ? 'UK' : country.selectorId);
            handleCountrySelection(country.selectorId, skipContextUpdate);
            setSelectedCountry(country);
            selectedCountryRef.current = country;
            setHoveredCountry(null);
        }
    }, [searchItems]);

    useEffect(() => {
        flags.forEach((country) => {
            const countryElem = document.getElementById(country.selectorId);
            countryElem?.addEventListener('click', onCountryClick);
            countryElem?.addEventListener('mouseover', onCountryOver);
            countryElem?.addEventListener('mouseout', onCountryOut);
        });

        return () => {
            flags.forEach((country) => {
                const countryElem = document.getElementById(country.selectorId);
                countryElem?.removeEventListener('click', onCountryClick);
                countryElem?.removeEventListener('mouseover', onCountryOver);
                countryElem?.removeEventListener('mouseout', onCountryOut);
            });
        };
    }, [countries]);

    useEffect(() => {
        const mapRefElem = mapRef?.current;
        mapRefElem.addEventListener('click', onMapClick, true);
        mapRefElem.addEventListener('transitionend', onTransitionEnd, true);

        return () => {
            mapRefElem.removeEventListener('click', onMapClick, true);
            mapRefElem.removeEventListener('transitionend', onTransitionEnd, true);
        };
    }, [mapRef, countries, forceRefresh]);

    useEffect(() => {
        setForceRefresh(!forceRefresh);
    }, [hoveredCountry, contextItems, selectedCountry]);

    return (
        <>
            <div className="map-wrapper" id="map-wrapper">
                <div className="map">
                    <MapSVG id="map" ref={mapRef} />
                </div>
                <CountryTooltip
                    country={hoveredCountry !== selectedCountry ? hoveredCountry : null}
                    getCountryData={getCountryData}
                    tooltipRef={hoveredTooltipRef}
                    showJobs
                    zIndex={10000}
                />
                {countryTooltipVariant()}
                <MapControls onClick={onZoomClick} scaleFactor={scale.current} />
            </div>
            {/* <div className="map-wrapper" id="map-wrapper">
                <div className="map">
                    <MapNewSVG />
                </div>
            </div> */}
            {isMobile && (
                <>
                    {selectedCountry ? (
                        <div className="map-specification-wrapper">
                            <CountryTooltipContent country={selectedCountry} showJobs getCountryData={getCountryData} />
                        </div>
                    ) : (
                        <div className="specification-wrapper">
                            <img src={earth} alt="" />
                            <p className="specification-subtext">{t('map.chooseLocation')}</p>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

function overridePosition(country, tooltipElem) {
    const countryPos = document.getElementById(country.selectorId).getBoundingClientRect();
    const mapWrapperPos = document.getElementById('map-wrapper').getBoundingClientRect();
    const tooltipPos = tooltipElem.getBoundingClientRect();

    const top = (countryPos.top + countryPos.height / 2 - mapWrapperPos.top - tooltipPos.height / 2) / 1;
    const left =
        (countryPos.left + countryPos.width / 2 - mapWrapperPos.left - tooltipPos.width / 2) / 1 +
        (country.countryId === 17 ? 80 : 0);
    // TO-DO: зробити кастомно для ЮКей
    // const opacity = tooltipPos.width > 0 && parseInt(tooltipElem.dataset.id, 10) === country?.countryId ? 1 : 0;
    const opacity = tooltipPos.width > 0 ? 1 : 0;

    return { top, left, opacity };
}

function CountryTooltip(props) {
    const { country, tooltipRef, zIndex, ...rest } = props;

    const style = country ? { ...overridePosition(country, tooltipRef.current), zIndex } : {};

    return (
        <div
            className={`tooltip ${country ? 'active' : ''}`}
            data-id={country?.countryId}
            ref={tooltipRef}
            style={style}
        >
            {country && <CountryTooltipContent country={country} {...rest} />}
        </div>
    );
}

function CountryTooltipContent(props) {
    const { country, showJobs = false, getCountryData } = props;

    const data = getCountryData(country?.countryId);
    const title = data?.country_title ?? country?.selectorId;
    const jobs = data?.vacancies_count ?? 0;

    return (
        <div className="tooltip-content">
            <img className="country-flag" src={country?.src} alt={title}></img>
            <span className="country-title">{`${title}`}</span>
            {showJobs && <span className="country-jobs">{`: ${jobs} jobs`}</span>}
        </div>
    );
}

function MapControls(props) {
    const { onClick } = props;

    return (
        <div className="zoom-buttons">
            <div role="button" className="zoom-button" onClick={() => onClick('zoom-in')}>
                <img className="zoom" src={zoomIn} alt="Zoom in" />
            </div>
            <div role="button" className="zoom-button" onClick={() => onClick('zoom-out')}>
                <img className="zoom" src={zoomOut} alt="Zoom out" width="20px" />
            </div>
        </div>
    );
}
