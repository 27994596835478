import React, { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { AppContext } from './context/appContext';

import './App.css';

import Home from './components/home/Home';
import JobDetails from './components/job-details/JobDetails';
import Application from './components/application/Application';
import ErrorPage from './components/error-page/ErrorPage';
import SuccessPage from './components/success-page/SuccessPage';
import TermsConditions from './components/terms-conditions/TermsConditions';
import { LanguageContext } from './context/lngContext';

const App = () => {
    const [languageContext, setLanguageContext] = useState({
        lang: 'en',
    });
    const [contextItems, setContextItems] = useState({
        countries: [],
        searchItems: {
            categories: [],
            countryId: null,
            // TO-DO: change to camel case
            job_types: [],
        },
        vacancies: [],
        isFetching: false,
        page: 0,
        showOverlay: false,
        showGreeting: true,
    });
    const value = { contextItems, setContextItems };
    const languageValue = { languageContext, setLanguageContext };

    return (
        <LanguageContext.Provider value={languageValue}>
            <AppContext.Provider value={value}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/search-job/:id/apply" element={<Application />} />
                    <Route path="/job-details/:id" element={<JobDetails />} />
                    <Route path="/error-page" element={<ErrorPage />} />
                    <Route path="/success-page" element={<SuccessPage />} />
                    <Route path="/terms-conditions" element={<TermsConditions />} />
                    <Route path="*" element={<Navigate to="/error-page" replace />} />
                </Routes>
            </AppContext.Provider>
        </LanguageContext.Provider>
    );
};

export default App;
