import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageContext } from '../../context/lngContext';

import './TermsConditions.css';
import TermsImage from '../../assets/terms.png';
import TermsImageMobile from '../../assets/terms-mobile.png';

import Footer from '../footer/Footer';
import Header from '../header/Header';

export default function TermsConditions() {
    const { t } = useTranslation();
    const { languageContext } = useContext(LanguageContext);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.id = 'CookieDeclaration';
        script.async = true;
        script.type = 'text/javascript';
        script.src = 'https://consent.cookiebot.com/b8136ca6-5152-4cff-a7d7-5c87d9d309a1/cd.js';
        const cookiesDiv = document.getElementById('cookies');

        cookiesDiv.appendChild(script);

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 450;

    return (
        <div className="terms-wrapper">
            <Header />
            <div className="hero-image-wrapper mt">
                <img className="terms-image" src={isMobile ? TermsImageMobile : TermsImage} alt={'details'} />
            </div>
            <div className="terms-content">
                <div className="text-block">
                    <h1 className="terms-title">{t('terms.title')}</h1>
                    <p className="terms-text">{t('terms.titleText')}</p>
                </div>
                <div className="text-block">
                    <h2 className="terms-item-title">{t('terms.disclaimer')}</h2>
                    <p className="terms-text-small">{t('terms.disclaimerText')}</p>
                </div>
                <div className="text-block">
                    <h2 className="terms-item-title">{t('terms.copyright')}</h2>
                    <p className="terms-text-small">{t('terms.copyrightText')}</p>
                    <p className="terms-text-small">{t('terms.copyrightText2')}</p>
                    <p className="terms-text-small">{t('terms.copyrightText3')}</p>
                </div>
                <div className="text-block">
                    <h2 className="terms-item-title">{t('terms.purpose')}</h2>
                    <p className="terms-text-small">{t('terms.purposeText')}</p>
                </div>
                <div className="text-block">
                    <h2 className="terms-item-title">{t('terms.disclosure')}</h2>
                    <p className="terms-text-small">{t('terms.disclosureText')}</p>
                    {languageContext.lang === 'en' && (
                        <>
                            <p className="terms-text-small mb-10">{t('terms.disclosureText2')}</p>
                            <div className="list-item-wrapper">
                                <div className="point"></div>
                                <p className="terms-text-small pl">{t('terms.disclosureText3')}</p>
                            </div>
                            <div className="list-item-wrapper">
                                <div className="point"></div>
                                <p className="terms-text-small pl">{t('terms.disclosureText4')}</p>
                            </div>
                        </>
                    )}
                </div>
                <div id="cookies" className="text-block">
                    <h2 className="terms-item-title">{t('terms.cookies')}</h2>
                    <p className="terms-text-small">{t('terms.cookiesText')}</p>
                </div>
                <div className="text-block">
                    <h2 className="terms-item-title">{t('terms.google')}</h2>
                    {languageContext.lang === 'en' && (
                        <>
                            {' '}
                            <p className="terms-text-small mb-10">{t('terms.googleText')}</p>
                            <div className="list-item-wrapper">
                                <div className="point"></div>
                                <p className="terms-text-small pl font-mobile">{t('terms.googleText2')}</p>
                            </div>
                            <div className="list-item-wrapper mb-24">
                                <div className="point"></div>
                                <p className="terms-text-small pl font-mobile">{t('terms.googleText3')}</p>
                            </div>
                        </>
                    )}
                    <p className="terms-text-small">{t('terms.googleText4')}</p>
                    <a href="https://support.google.com/analytics/answer/2763052" target="blank">
                        <p className="terms-text-small">{t('terms.googleText5')}</p>
                    </a>
                    <p className="terms-text-small mt-24">{t('terms.googleText6')}</p>
                    <p className="terms-text-small">
                        {t('terms.googleText7')}
                        <a href="https://support.google.com/analytics/answer/2763052" target="blank">
                            <span className="terms-text-small">{t('terms.googleText8')}</span>
                        </a>
                        <span className="terms-text-small">{t('terms.googleText9')}</span>
                    </p>
                </div>
                <div className="text-block">
                    <h2 className="terms-item-title">{t('terms.virus')}</h2>
                    <p className="terms-text-small">{t('terms.virusText')}</p>
                </div>
                <div className="text-block">
                    <h2 className="terms-item-title">{t('terms.links')}</h2>
                    <p className="terms-text-small">{t('terms.linksText')}</p>
                </div>
                <div className="text-block">
                    <h2 className="terms-item-title">{t('terms.accuracy')}</h2>
                    <p className="terms-text-small">
                        {t('terms.accuracyText')}
                        <a href="mailto: web@spar-international.com.">
                            <span className="terms-text-small">{t('terms.accuracyText2')}</span>
                        </a>
                    </p>
                    <p className="terms-text-small mt-24">{t('terms.accuracyText3')}</p>
                </div>
            </div>
            <Footer />
        </div>
    );
}
