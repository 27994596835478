import React, { useState, useContext, useEffect } from 'react';

import './FilterGroups.css';
import { AppContext } from '../../context/appContext';
import checked from '../../assets/checked.svg';

function FilterGroupJobTypes({ jobTypes }) {
    const { contextItems, setContextItems } = useContext(AppContext);
    const [chosenJobTypes, setChosenJobTypes] = useState({});

    const setPreselections = () => {
        let jts = { part_time: false, full_time: false };

        contextItems.searchItems.job_types.forEach((item) => {
            if (Object.keys(jts).includes(item)) {
                jts[item] = true;
            }
        });
        return jts;
    };

    useEffect(() => {
        setChosenJobTypes(setPreselections());
    }, [contextItems.searchItems.categories]);

    useEffect(() => {
        const newArray = [];
        for (let key in chosenJobTypes) {
            if (chosenJobTypes[key]) {
                newArray.push(key);
            }
        }
        // console.log('new ARRAY: ', newArray);
        setContextItems({
            ...contextItems,
            searchItems: {
                ...contextItems.searchItems,
                job_types: newArray,
            },
        });
    }, [chosenJobTypes]);

    const jobTypeChooseHandler = (name) => {
        setChosenJobTypes({ ...chosenJobTypes, [name]: !chosenJobTypes[name] });
    };

    return (
        <div className="subcategories-wrapper">
            {jobTypes.map((item) => (
                <div key={item.id} className="subcategory-block">
                    <div className="subcategory-title-wrapper">
                        <div
                            className={
                                chosenJobTypes[item.name] ? 'checkbox-checker checkbox-checker-c' : 'checkbox-checker'
                            }
                            onClick={() => jobTypeChooseHandler(item.name)}
                        >
                            {chosenJobTypes[item.name] && (
                                <img className="subcategory-checked" src={checked} alt="checked" />
                            )}
                        </div>
                        <div className="subcategory-checkbox-wrapper" onClick={() => jobTypeChooseHandler(item.name)}>
                            <span className={'subcategory-title subcategory-title-l'}>{item.title}</span>
                            <span className="subcategory-jobs-number">{`(${item.jobsAmount})`}</span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default FilterGroupJobTypes;
