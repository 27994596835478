import './GreetingSection.css';
import happyImage from '../../assets/happyFaces.png';
import TextBlock from '../textblock/TextBlock';

export default function GreetingSection() {
    const textBlockProps = {
        text1: 'Наші партнери регулярно знаходяться у пошуку нових працівників для роботи в магазинах, на складах або ж місцевих офісах SPAR.',
        text2: 'Щоб подати заявку на роботу, будь ласка, виберіть країну, яка вас цікавить, в меню нижче. Вакансії компанії SPAR International в Амстердамі ви можете знайти у правій колонці.',
    };

    return (
        <div className="greeting-background">
            <div className="greeting-wrapper">
                <img src={happyImage} alt="happyFaces" />
                <div className="greeting-text-wrapper">
                    <h1 className="greeting-title">Ми раді вітати претендентів з України</h1>
                    <TextBlock {...textBlockProps} />
                </div>
            </div>
        </div>
    );
}
