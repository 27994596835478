import './CategoryGroups.css';
import { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../context/appContext';

import Categories from './Categories';

import checked from '../../assets/checked.svg';

export default function CategoryGroups({ categoryGroups }) {
    const { contextItems, setContextItems } = useContext(AppContext);

    const [chosenCategoryGroups, setChosenCategoryGroups] = useState({});

    const categoriesGroupChooseHandler = (categoryGroupID) => {
        setChosenCategoryGroups({ ...chosenCategoryGroups, [categoryGroupID]: !chosenCategoryGroups[categoryGroupID] });

        let newContextArray = [];
        const currentCategoryGroup = categoryGroups.find((item) => item.categories_group_id === categoryGroupID);

        if (!chosenCategoryGroups[categoryGroupID]) {
            newContextArray = [
                ...contextItems.searchItems.categories,
                ...currentCategoryGroup.categories.map((item) => item.category_id),
            ];
            // newContextArray = rawArray.filter((val, ind, arr) => arr.indexOf(val) === ind); //filter unique ids
        } else {
            const deleteArray = currentCategoryGroup.categories.map((item) => item.category_id);
            newContextArray = contextItems.searchItems.categories.filter((id) => {
                if (!deleteArray.includes(id)) return id;
            });
        }

        setContextItems({
            ...contextItems,
            searchItems: {
                ...contextItems.searchItems,
                categories: newContextArray,
            },
        });
    };

    const renderItems = categoryGroups?.map((item) => {
        const checkboxClasses = chosenCategoryGroups[item.categories_group_id]
            ? 'filter-checkbox-checker filter-checkbox-checker-c'
            : 'filter-checkbox-checker';

        const titleClasses = chosenCategoryGroups[item.categories_group_id]
            ? 'category-group-title-wrapper checked'
            : 'category-group-title-wrapper';

        return (
            <div key={item.categories_group_id} className="subcategory-i-block">
                <div
                    key={item.categories_group_id}
                    className={titleClasses}
                    onClick={() => categoriesGroupChooseHandler(item.categories_group_id)}
                >
                    <div className={checkboxClasses}>
                        {chosenCategoryGroups[item.categories_group_id] && (
                            <img className="subcategory-checked" src={checked} alt="checked" />
                        )}
                    </div>
                    <div className="subcategory-checkbox-wrapper">
                        <span className="subcategory-title">{item.categories_group_title}</span>
                        <span className="subcategory-jobs-number">{`(${item.vacancies_count})`}</span>
                    </div>
                </div>

                <Categories categories={item.categories} />
            </div>
        );
    });
    return <>{renderItems}</>;
}
// по ходу це зайве...))))

// const setIDes = (toggler) => {
//     let ids = {};
//     list.forEach((item) => {
//         ids = { ...ids, [item.categories_group_id]: toggler };
//     });
//     return ids;
// };

// useEffect(() => {
//     setChosenCategoryGroups(setIDes(false));
//     // console.log('LIST: ', list);
// }, []);

// useEffect(() => {
//     if (contextItems.searchItems.categories.length === 0) {
//         setChosenCategoryGroups(setIDes(false));
//     }
// }, [contextItems.searchItems.categories]);
