import './JobInfo.css';
import address from '../../assets/location.svg';
import start_date from '../../assets/entry.svg';
import entry_level from '../../assets/entryLevel.svg';
import salary from '../../assets/salary.svg';
import spar_unique_id from '../../assets/id.svg';
import { useTranslation } from 'react-i18next';

const icons = { address, start_date, entry_level, salary, spar_unique_id };
const infoKeys = ['address', 'start_date', 'entry_level', 'salary', 'spar_unique_id'];

export default function JobInfo({ job }) {
    const { t } = useTranslation();

    const filteredInfoKeys = infoKeys.filter((key) => job[key]);

    const infoTitles = {
        address: t('main.location'),
        start_date: t('main.entry'),
        entry_level: t('main.entryLevel'),
        salary: t('main.salary'),
        spar_unique_id: 'ID',
    };

    const renderItems = filteredInfoKeys.map((item, index) => {
        return (
            <div key={index} className="info-wrapper">
                <img src={icons[item]} alt={item} />
                <div className="info-content-wrapper">
                    <p className="info-item-title">{infoTitles[item]}</p>
                    <p className="info-item-content">{job?.[item]}</p>
                </div>
            </div>
        );
    });
    return <>{renderItems}</>;
}
