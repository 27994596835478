import React, { useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';

import './Home.css';
import { AppContext } from '../../context/appContext';
import Map from '../map/Map';
import HeroVacancies from './HeroVacancies';
import GreetingSection from './GreetingSection';
import Footer from '../footer/Footer';
import JobsSection from '../jobslist/JobsSection';
import Overlay from '../overlay/Overlay';
import Header from '../header/Header';
import { LanguageContext } from '../../context/lngContext';
import { useNavigate } from 'react-router-dom';

const sortCategories = (a, b) => {
    if (a.categories_group_id > b.categories_group_id) {
        return 1;
    }
    if (a.categories_group_id < b.categories_group_id) {
        return -1;
    }
    return 0;
};

export default function Home() {
    const navigate = useNavigate();
    const { contextItems, setContextItems } = useContext(AppContext);
    const { languageContext } = useContext(LanguageContext);
    const [countries, setCountries] = useState([]);
    const [categories, setCategories] = useState({});
    const [width, setWidth] = useState(window.innerWidth);
    const [needScroll, setNeedScroll] = useState(false);

    const currentContext = useRef({});
    const isFetching = useRef(false);
    const page = useRef(0);
    const vacancies = useRef([]);

    const selectedCountryId = contextItems.searchItems.countryId;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        // console.log('PROCESS: ', process);
        window.addEventListener('resize', handleWindowSizeChange);
        document.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            document.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        currentContext.current = contextItems;
        isFetching.current = contextItems.isFetching;

        if (contextItems.isFetching) {
            const searchCategories = contextItems.searchItems.categories.length > 0;

            axios({
                method: 'get',
                url: 'https://spar.jobs:8080/api/get/vacancies',
                // url: 'https://eval.spar.jobs:8080/api/get/vacancies',
                params: {
                    lang: languageContext.lang,
                    country_id: selectedCountryId,
                    categories_ids: searchCategories ? `[${contextItems.searchItems.categories}]` : undefined,
                    job_types: JSON.stringify(contextItems.searchItems.job_types),
                    page: contextItems.page,
                    per_page: 30,
                },
            })
                .then((response) => {
                    setContextItems({
                        ...contextItems,
                        vacancies: [...contextItems.vacancies, ...response.data],
                        page: contextItems.page + 1,
                        isFetching: false,
                        categories: [],
                    });

                    vacancies.current = [...contextItems.vacancies, ...response.data];
                    isFetching.current = false;

                    if (!contextItems.vacancies.length) {
                        setNeedScroll(!needScroll);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    navigate('/error-page');
                });
        }
    }, [contextItems.isFetching]);

    useEffect(() => {
        if (!contextItems.showGreeting) {
            const jobSection = document.getElementById('job-section').getBoundingClientRect();
            window.scrollTo({
                top: jobSection.top,
                behavior: 'smooth',
            });
        }
    }, [needScroll]);

    useEffect(() => {
        page.current = contextItems.page;
    }, [contextItems.vacancies]);

    const scrollHandler = (e) => {
        if (isFetching.current || vacancies.current.length === vacancies.current[0]?.total) {
            return;
        }

        if (
            page.current > 1 &&
            e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 200
        ) {
            setContextItems({
                ...currentContext.current,
                isFetching: true,
            });
        }
    };

    const isMobile = width <= 768;

    useEffect(() => {
        axios({
            method: 'get',
            url: `https://spar.jobs:8080/api/get/countries?lang=${languageContext.lang}`,
            // url: `https://eval.spar.jobs:8080/api/get/countries?lang=${languageContext.lang}`,
        })
            .then((response) => {
                setCountries(response.data.by_country);
                setCategories(response.data.by_category.sort(sortCategories));
                setContextItems({
                    ...contextItems,
                    countries: response.data.by_country,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }, [languageContext.lang]);

    useEffect(() => {
        if (isMobile) {
            const body = document.querySelector('body');
            if (contextItems.showOverlay) {
                body.style.overflow = 'hidden';
            } else {
                body.style.overflow = 'auto';
            }
        }
    }, [isMobile, contextItems.showOverlay]);

    return (
        <div className="app-content-wrapper app-background">
            <Header absolute />

            <div className="hero-wrapper">
                <HeroVacancies countries={countries} allCategories={categories} />
                {!isMobile && <Map countries={countries} />}
            </div>

            {contextItems.showGreeting && <GreetingSection />}
            {!contextItems.showGreeting && <JobsSection countries={countries} categories={categories} />}

            <Footer absolute />

            {contextItems.showOverlay && <Overlay countries={countries} categories={categories} />}
        </div>
    );
}
