import './FilterGroups.css';
import { useState, useContext, useEffect } from 'react';

import { AppContext } from '../../context/appContext';
import Categories from './Categories';
import arrowDown from '../../assets/arrowdown.svg';
import arrowUpGreen from '../../assets/arrow-up-green.svg';
import checked from '../../assets/checked.svg';

export default function FilterGroupCategories({ categoryGroups }) {
    const { contextItems, setContextItems } = useContext(AppContext);
    const [chosenCategoryGroups, setChosenCategoryGroups] = useState({});
    const [shownCategories, setShownCategories] = useState({});

    useEffect(() => {
        if (!contextItems.searchItems.categories.length) {
            setChosenCategoryGroups({});
        }
    }, [contextItems.searchItems.categories.length]);

    const categoryGroupOpenHandler = (id) => {
        setShownCategories({ ...shownCategories, [id]: !shownCategories[id] });
    };

    const categoryGroupChooseHandler = (id) => {
        setChosenCategoryGroups({ ...chosenCategoryGroups, [id]: !chosenCategoryGroups[id] });
        if (!shownCategories[id]) {
            categoryGroupOpenHandler(id);
        }

        let newContextArray = [];
        const currentCategoryGroup = categoryGroups.find((item) => item.categories_group_id === id);

        if (!chosenCategoryGroups[id]) {
            newContextArray = [
                ...contextItems.searchItems.categories,
                ...currentCategoryGroup.categories.map((item) => item.category_id),
            ];
            // newContextArray = rawArray.filter((val, ind, arr) => arr.indexOf(val) === ind); //filter unique ids
        } else {
            const deleteArray = currentCategoryGroup.categories.map((item) => item.category_id);
            newContextArray = contextItems.searchItems.categories.filter((id) => {
                if (!deleteArray.includes(id)) return id;
            });
        }

        setContextItems({
            ...contextItems,
            searchItems: {
                ...contextItems.searchItems,
                categories: newContextArray,
            },
        });
    };

    const renderItems = () => {
        const renderCategoryGroups = categoryGroups.map((item) => {
            let checkboxCheckedClass = chosenCategoryGroups[item.categories_group_id]
                ? 'checkbox-checker checkbox-checker-c'
                : 'checkbox-checker';

            return (
                <div key={item.categories_group_id} className="subcategory-block">
                    <div className="subcategory-title-wrapper">
                        <div
                            className={checkboxCheckedClass}
                            onClick={() => categoryGroupChooseHandler(item.categories_group_id)}
                        >
                            {chosenCategoryGroups[item.categories_group_id] && (
                                <img className="subcategory-checked" src={checked} alt="checked" />
                            )}
                        </div>
                        <div
                            className="subcategory-checkbox-wrapper"
                            onClick={() => categoryGroupOpenHandler(item.categories_group_id)}
                        >
                            <span className={'subcategory-title'}>{item.categories_group_title}</span>
                            <span className="subcategory-jobs-number">{`(${item.vacancies_count})`}</span>
                        </div>
                        <img
                            src={shownCategories[item.categories_group_id] ? arrowUpGreen : arrowDown}
                            alt="arrow-down"
                            onClick={() => categoryGroupOpenHandler(item.categories_group_id)}
                            style={{ cursor: 'pointer', marginLeft: 'auto' }}
                        />
                    </div>

                    {shownCategories[item.categories_group_id] && <Categories categories={item.categories} />}
                </div>
            );
        });

        return <>{renderCategoryGroups}</>;
    };
    return <div className="subcategories-wrapper">{renderItems()}</div>;
}
