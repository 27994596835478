import React, { useEffect, useRef, useState } from 'react';

import './DropdownSelect.css';

export default function DropdownSelect({ className, onChange, value, items, placeholder }) {
    const [open, setOpen] = useState(false);
    const wrapperRef = useRef(null);

    const onSelect = (item) => {
        onChange(item);
        setOpen(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, setOpen]);


    return (
        <div ref={wrapperRef} className={`dropdown-input-field ${className}`} onClick={() => setOpen(!open)}>
            <div className={`dropdown-input-value ${open ? 'open' : ''}`}>
                {value || placeholder}
            </div>
            {open && <ul className="dropdown-select">
                {items.map(item => (
                    <li className="select-item" key={item} onClick={() => onSelect(item)}>{item}</li>
                ))}
            </ul>}
        </div>
    );
};
