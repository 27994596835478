import { useState, useEffect } from 'react';

import './JobItem.css';
import moreDetails from '../../assets/more-details.svg';
import lessDetails from '../../assets/less-details.svg';
import JobOverview from './JobOverview';

export default function JobItem({ isMobile, foundJobs, searchItems }) {
    const [openDetailsJobIDes, setOpenDetailsJobIDes] = useState([]);


    useEffect(() => {
        setOpenDetailsJobIDes([]);
    }, [searchItems]);

    const jobDetailsClickHandler = (jobID) => {
        let newArray = [...openDetailsJobIDes];
        const index = openDetailsJobIDes.indexOf(jobID);

        if (index > -1) {
            newArray.splice(index, 1);
        } else {
            newArray.push(jobID);
        }
        setOpenDetailsJobIDes(newArray);
    };

    return <>
        {foundJobs.map((job) => (
            <div key={job.vacancy_id}>
                <div className="short-details-wrapper">
                    <div className="item-wrapper" onClick={() => jobDetailsClickHandler(job.vacancy_id)}>
                        <img
                            src={openDetailsJobIDes.includes(job.vacancy_id) ? lessDetails : moreDetails}
                            alt="cross"
                        />
                        {!isMobile && <p className="job-title">{job.vacancy_title}</p>}
                        {isMobile && <div>
                            <p className="job-title">{job.vacancy_title}</p>
                            <p className="location">{job.address}</p>
                        </div>}
                    </div>
                    {!isMobile && <p className="location">{job.address}</p>}
                </div>
                {openDetailsJobIDes.includes(job.vacancy_id) && <JobOverview
                    jobID={job.vacancy_id}
                    openDetails={openDetailsJobIDes}
                    job={job}
                    isMobile={isMobile}
                />}
            </div>
        ))}
    </>;
}
