import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import './Application.css';
import ApplicationForm from './ApplicationForm';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import DetailsImage from '../../assets/details-hero.png';
import { AppContext } from '../../context/appContext';
import { LanguageContext } from '../../context/lngContext';

export default function Application() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { t } = useTranslation();
    const { languageContext } = useContext(LanguageContext);
    const { contextItems, setContextItems } = useContext(AppContext);
    const [vacancy, setVacancy] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    const onSubmit = (values) => {
        const data = {
            id: id,
            firstName: values.firstName,
            lastName: values.lastName,
            birthDate: values.birthDate,
            gender: values.gender,
            country: values.country,
            city: values.city,
            address: values.address,
            houseNumber: values.houseNumber,
            floor: values.floor,
            apartmentNumber: values.apartmentNumber,
            email: values.email,
            phone: values.phone,
            cv: values.cv,
            motivation: values.motivation,
        };

        const formData = new FormData();

        for (var key in data) {
            formData.append(key, data[key]);
        }

        axios
            .post('https://spar.jobs:8080/api/apply/vacancy', formData, {
                // .post('https://eval.spar.jobs:8080/api/apply/vacancy', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(() => {
                navigate('/success-page');
            })
            .catch((error) => {
                console.error(error);
                navigate('/error-page');
            });
    };

    const getJob = useCallback(
        (id) => {
            axios
                .get(`https://spar.jobs:8080/api/get/vacancy`, {
                    // .get(`https://eval.spar.jobs:8080/api/get/vacancy`, {
                    params: {
                        vacancy_id: id,
                        lang: languageContext.lang || 'en',
                    },
                })
                .then((response) => {
                    setVacancy(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    navigate('/error-page');
                });
        },
        [id, languageContext.lang]
    );

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    useEffect(() => {
        getJob(id);
    }, [id, languageContext.lang]);

    const goBack = (dest) => {
        if (dest === 'job-details') {
            navigate(`/job-details/${id}`);
            return;
        }

        setContextItems({
            ...contextItems,
            searchItems:
                dest === 'home' ? { categories: [], countryId: null, job_types: [] } : contextItems.searchItems,
            vacancies: dest === 'home' ? [] : contextItems.vacancies,
            showGreeting: dest === 'home',
        });

        navigate('/');
    };

    return (
        <div className="app-content-wrapper">
            <div className="application-wrapper">
                <Header />
                <div className="sub-header">
                    <div className="bread-crumbs-wrapper">
                        <span onClick={() => goBack('home')}>{t('header.home')}</span>
                        <span>{'>'}</span>
                        <span onClick={() => goBack('jobs')}>{t('header.searchJob')}</span>
                        <span>{'>'}</span>
                        <span onClick={() => goBack('job-details')}>{vacancy?.vacancy_title}</span>
                        <span>{'>'}</span>
                        <span>{t('form.application')}</span>
                    </div>
                    <div className="hero-image-wrapper">
                        <img className="details-image" src={DetailsImage} alt={'details'} />
                    </div>
                </div>
                <div className="application-form-wrapper">
                    <ApplicationForm vacancy={vacancy} isMobile={isMobile} onSubmit={onSubmit} />
                </div>

                <Footer />
            </div>
        </div>
    );
}
