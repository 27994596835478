import React, { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from '../../context/appContext';
import { useTranslation } from 'react-i18next';

import './HeroVacancies.css';
import Dropdown from '../dropdown/Dropdown';
import TextBlock from '../textblock/TextBlock';
import CategoryGroups from './CategoryGroups';

import flash from '../../assets/flash.svg';
import ArrowBack from '../../assets/arrow-back.svg';
import { LanguageContext } from '../../context/lngContext';
import Map from '../map/Map';

export default function HeroVacancies({ countries, allCategories }) {
    const { t } = useTranslation();
    const { languageContext } = useContext(LanguageContext);
    const [searchTogglers, setSearchTogglers] = useState({ categoriesFilter: false, country: false });
    const [searchCountry, setSearchCountry] = useState({});
    const [categoryGroupSearchFieldContent, setCategoryGroupSearchFieldContent] = useState(t('header.whatJob'));
    const [categoriesClasses, setCategoriesClasses] = useState('search-item');
    const [countriesClasses, setCountriesClasses] = useState('search-item');
    const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);
    const [categories, setCategories] = useState([]);
    const [needScroll, setNeedScroll] = useState(false);

    const { contextItems, setContextItems } = useContext(AppContext);
    const selectedCountryId = contextItems.searchItems.countryId;

    const [width, setWidth] = useState(window.innerWidth);

    const storedCategories = useRef([]);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        setCategories(allCategories);
    }, [allCategories]);

    const isMobile = width <= 768;

    const textBlockProps = {
        text1: t('header.areYouLooking'),
        text2: t('header.lookNoFurther'),
    };

    // TO-DO: у деталі вакансії передбачити вивод всіх деталей
    // TO-DO: кнопка селект олл
    // TO-DO: можливо переробити у сторі country -> object

    useEffect(() => {
        setCategoriesClasses(!searchTogglers.categoriesFilter ? 'search-item' : 'search-item si-open');
        setCountriesClasses(!searchTogglers.country ? 'search-item' : 'search-item si-open');
    }, [searchTogglers]);

    // TO-DO: це ефект перевірити, може спрацьовувати, коли не треба
    useEffect(() => {
        searchButtonClickHandler();
    }, [languageContext.lang]);

    useEffect(() => {
        const chosenCountry = countries.find((item) => item.country_id === selectedCountryId);
        if (chosenCountry) {
            setSearchCountry(chosenCountry);
        } else {
            setSearchCountry({});
            setCategoryGroupSearchFieldContent(t('header.whatJob'));
        }
    }, [selectedCountryId, countries, t]);

    useEffect(() => {
        if (!searchCountry.country_id) {
            return;
        }

        const chosenCountry = countries.find((item) => item.country_id === searchCountry.country_id);
        setCategoryGroupSearchFieldContent(t('header.jobsCount', { count: chosenCountry.vacancies_count }));
    }, [searchCountry]);

    useEffect(() => {
        window.scrollTo({
            top: window.top,
            behavior: 'smooth',
        });
    }, [needScroll]);

    useEffect(() => {
        // refactor - function

        if (contextItems.vacancies.length) {
            const searchCategories = contextItems.searchItems.categories;

            if (searchCategories.length === 0) {
                if (selectedCountryId) {
                    const chosenCountry = contextItems.countries.find((item) => {
                        if (item.country_id === selectedCountryId) return item;
                    });

                    setCategoryGroupSearchFieldContent(t('header.jobsCount', { count: chosenCountry.vacancies_count }));
                } else {
                    setCategoryGroupSearchFieldContent(t('header.whatJob'));
                }
                return;
            }

            setCategoryGroupSearchFieldContent(t('header.jobsCount', { count: contextItems.vacancies[0].total }));
        }
    }, [contextItems.vacancies]);

    const searchFieldsClickHandler = (e, searchKey) => {
        // e.stopPropagation();

        if (searchKey === 'categoriesFilter') {
            if (!searchTogglers.categoriesFilter) {
                if (contextItems.searchItems.categories.length > 0) {
                    storedCategories.current = contextItems.searchItems.categories;
                }

                if (searchCountry.country_id) {
                    const chosenCountry = countries.find((item) => item.country_id === searchCountry.country_id);
                    if (chosenCountry) {
                        const newCategories = chosenCountry.categories_group.sort((a, b) => {
                            if (a.categories_group_id > b.categories_group_id) {
                                return 1;
                            }
                            if (a.categories_group_id < b.categories_group_id) {
                                return -1;
                            }
                            return 0;
                        });

                        setCategories(newCategories);
                    }
                }
            } else {
                setContextItems({
                    ...contextItems,
                    searchItems: {
                        ...contextItems.searchItems,
                        categories: storedCategories.current,
                    },
                });
                storedCategories.current = [];
            }
        } else {
            setContextItems({
                ...contextItems,
                searchItems: {
                    ...contextItems.searchItems,
                    categories: storedCategories.current,
                },
            });
        }

        const otherSearchKey = searchKey === 'categoriesFilter' ? 'country' : 'categoriesFilter';
        setSearchTogglers({ [otherSearchKey]: false, [searchKey]: !searchTogglers[searchKey] });
    };

    const applyCategoriesClickHandler = () => {
        setSearchTogglers({ categoriesFilter: false, country: false });

        const searchCategories = contextItems.searchItems.categories;

        if (searchCategories.length === 0) {
            if (selectedCountryId) {
                const chosenCountry = countries.find((item) => {
                    if (item.country_id === selectedCountryId) return item;
                });

                setCategoryGroupSearchFieldContent(t('header.jobsCount', { count: chosenCountry.vacancies_count }));
            }
            return;
        }

        let jobsCount = 0;
        for (let i = 0; i < categories.length; i++) {
            categories[i].categories.forEach((item) => {
                if (searchCategories.includes(item.category_id)) {
                    jobsCount = jobsCount + item.vacancies_count;
                }
            });
        }

        if (jobsCount > 0) {
            setCategoryGroupSearchFieldContent(t('header.jobsCount', { count: jobsCount }));
        }

        setContextItems({
            ...contextItems,
            vacancies: [],
            page: 0,
        });
    };

    const countryFilterClickHandler = (itemID) => {
        // if (itemID===)
        const selectedCountry = countries.find((country) => {
            return country.country_id === itemID;
        });
        setSearchCountry(selectedCountry);

        setContextItems({
            ...contextItems,
            vacancies: [],
            page: 0,
            searchItems: {
                ...contextItems.searchItems,
                countryId: selectedCountry.country_id,
                categories: [],
            },
        });

        storedCategories.current = [];

        setNeedScroll(!needScroll);
        setSearchTogglers({ categoriesFilter: false, country: false });
    };

    const clearHandler = () => {
        setContextItems({
            ...contextItems,
            searchItems: {
                ...contextItems.searchItems,
                categories: [],
            },
        });
    };

    const selectAllHandler = () => {
        let allCategories = [];
        for (let i = 0; i < categories.length; i++) {
            allCategories = [...allCategories, ...categories[i].categories.map((item) => item.category_id)];
        }
        setContextItems({
            ...contextItems,
            searchItems: {
                ...contextItems.searchItems,
                categories: allCategories,
            },
        });
    };

    const searchButtonClickHandler = () => {
        if (searchTogglers.categoriesFilter || (!contextItems.searchItems.categories.length && !selectedCountryId)) {
            return;
        }

        setContextItems({
            ...contextItems,
            vacancies: [],
            page: 1,
            isFetching: true,
            showGreeting: false,
            searchItems: {
                ...contextItems.searchItems,
                job_types: [],
            },
        });

        setSearchTogglers({ categoriesFilter: false, country: false });
    };

    const stopBubbles = (event) => {
        event.stopPropagation();
    };

    const showSearchModal = () => {
        setIsMobileSearchVisible(true);

        // todo move to function
        const body = document.querySelector('body');
        body.style.overflow = 'hidden';
    };

    const closeSearchModal = () => {
        setIsMobileSearchVisible(false);

        // todo move to function
        const body = document.querySelector('body');
        body.style.overflow = 'auto';
    };

    const searchButtonMobileClickHandler = () => {
        setIsMobileSearchVisible(false);
        searchButtonClickHandler();
        // todo move to function
        const body = document.querySelector('body');
        body.style.overflow = 'auto';
    };

    const closeFilterCategoriesModal = () => {
        setSearchTogglers({ categoriesFilter: false, country: false });
    };

    // render helpers
    const specsContent = () => {
        return (
            <div className="specification-wrapper">
                <img src={flash} alt="flash" />
                <p className="specification-subtext">{t('header.refineYourJob')}</p>
            </div>
        );
    };

    return (
        <>
            <div className="vacancies-wrapper">
                <h1 className="vacancies-title">{t('header.worldwideVacancies')}</h1>
                <TextBlock {...textBlockProps} />
                {!isMobile && specsContent()}

                {isMobile && (
                    <button className="button-primary handle-search" onClick={showSearchModal}>
                        {t('header.searchJob')}
                    </button>
                )}

                {!isMobile && (
                    <div className="shadow">
                        <div className="search-wrapper">
                            <div className="search-item-wrapper">
                                <div
                                    id="s-i-1"
                                    className={categoriesClasses}
                                    onClick={(e) => searchFieldsClickHandler(e, 'categoriesFilter')}
                                >
                                    {categoryGroupSearchFieldContent}
                                </div>
                                {searchTogglers.categoriesFilter && (
                                    <div id="s-i-3" className="filter-categories-wrapper">
                                        <div className="filter-categories-title-wrapper" onClick={stopBubbles}>
                                            <p className="filter-categories-title">{t('header.filter')}</p>
                                            <p className="filter-categories-select-all" onClick={selectAllHandler}>
                                                {t('header.selectAll')}
                                            </p>
                                        </div>
                                        <div className="filter-categories-items-wrapper">
                                            <CategoryGroups categoryGroups={categories} />
                                        </div>
                                        <div className="filter-categories-buttons-wrap">
                                            <div className="filter-categories-buttons">
                                                <button
                                                    className="button mr-12 button-full-with"
                                                    onClick={clearHandler}
                                                >
                                                    {t('header.clear')}
                                                </button>
                                                <button
                                                    className="button-primary button-full-with"
                                                    onClick={applyCategoriesClickHandler}
                                                >
                                                    {t('header.apply')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="search-item-wrapper">
                                <div
                                    id="s-i-2"
                                    className={countriesClasses}
                                    onClick={(e) => searchFieldsClickHandler(e, 'country')}
                                >
                                    {searchCountry.country_title ? searchCountry.country_title : t('header.location')}
                                </div>
                                {searchTogglers.country && (
                                    <Dropdown
                                        onChoose={countryFilterClickHandler}
                                        list={countries}
                                        searchItem="country"
                                    />
                                )}
                            </div>

                            <button className="button-primary" onClick={searchButtonClickHandler}>
                                {t('header.searchJob')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {isMobileSearchVisible && (
                <div className="search-wrapper-mobile">
                    <div className="go-back-wrap">
                        <button className="back-btn" onClick={closeSearchModal}>
                            <img src={ArrowBack} alt="back" />
                        </button>
                        <div>{t('header.searchJob')}</div>
                    </div>

                    {specsContent()}

                    <Map countries={countries} />

                    <div className="search-item-wrapper">
                        <div
                            id="s-i-1"
                            className={categoriesClasses}
                            onClick={(e) => searchFieldsClickHandler(e, 'categoriesFilter')}
                        >
                            {categoryGroupSearchFieldContent}
                        </div>
                        {searchTogglers.categoriesFilter && (
                            <div id="s-i-3" className="filter-categories-wrapper">
                                <div className="filter-categories-title-wrapper" onClick={stopBubbles}>
                                    <div className="filter-categories-close">
                                        <button className="back-btn" onClick={closeFilterCategoriesModal}>
                                            <img src={ArrowBack} alt="back" />
                                        </button>
                                        <div className="filter-categories-title">{t('header.filter')}</div>
                                    </div>
                                    <p className="filter-categories-select-all" onClick={selectAllHandler}>
                                        {t('header.selectAll')}
                                    </p>
                                </div>
                                <div className="filter-categories-items-wrapper">
                                    <CategoryGroups categoryGroups={categories} />
                                </div>
                                <div className="filter-categories-buttons-wrap">
                                    <div className="filter-categories-buttons">
                                        <button className="button mr-12 button-full-with" onClick={clearHandler}>
                                            {t('header.clear')}
                                        </button>
                                        <button
                                            className="button-primary button-full-with"
                                            onClick={applyCategoriesClickHandler}
                                        >
                                            {t('header.apply')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="search-item-wrapper">
                        <div
                            id="s-i-2"
                            className={countriesClasses}
                            onClick={(e) => searchFieldsClickHandler(e, 'country')}
                        >
                            {searchCountry.country_title ? searchCountry.country_title : t('header.location')}
                        </div>
                        {searchTogglers.country && (
                            <Dropdown onChoose={countryFilterClickHandler} list={countries} searchItem="country" />
                        )}
                    </div>

                    <button className="button-primary" onClick={searchButtonMobileClickHandler}>
                        {t('header.searchJob')}
                    </button>
                </div>
            )}
        </>
    );
}
