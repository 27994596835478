import './SortingItem.css';
import { useContext } from 'react';
import { AppContext } from '../../context/appContext';

import arrowUp from '../../assets/arrowup.svg';
import arrowDown from '../../assets/arrowdown.svg';

export default function SortingItem({ itemName, field }) {
    const { contextItems, setContextItems } = useContext(AppContext);

    const sortVacancies = (order, field) => {
        const sortedVacancies = contextItems.vacancies.sort((a, b) => {
            if (order === 'asc' ? a[field] > b[field] : a[field] < b[field]) {
                return 1;
            }
            if (order === 'asc' ? a[field] < b[field] : a[field] > b[field]) {
                return -1;
            }
            return 0;
        });
        setContextItems({
            ...contextItems,
            vacancies: sortedVacancies,
        });
    };

    return (
        <div className="sorting-item-wrapper">
            <p className="sorting-item">{itemName}</p>
            <div className="sorting-directions-wrapper">
                <img src={arrowUp} alt="arrow-up" onClick={() => sortVacancies('asc', field)} />
                <img src={arrowDown} alt="arrow-down" onClick={() => sortVacancies('desc', field)} />
            </div>
        </div>
    );
}
