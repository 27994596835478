import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/appContext';

import './JobsSection.css';

import JobItem from './JobItem';
import SortingItem from './SortingItem';

import filter from '../../assets/filter.svg';
import lineClose from '../../assets/line-category-close.svg';
import clear from '../../assets/clear.svg';
import { useTranslation } from 'react-i18next';

export default function JobsSection({ countries, categories }) {
    const { t } = useTranslation();
    const { contextItems, setContextItems } = useContext(AppContext);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    const filterClick = () => {
        setContextItems({
            ...contextItems,
            showOverlay: true,
        });
    };

    const refetchVacancies = (deleteID) => {
        const newCategories = deleteID ? contextItems.searchItems.categories.filter((item) => item !== deleteID) : [];
        const newJobTypes = deleteID ? contextItems.searchItems.job_types : [];

        setContextItems({
            ...contextItems,
            vacancies: [],
            page: 1,
            isFetching: true,
            searchItems: {
                ...contextItems.searchItems,
                categories: newCategories,
                job_types: newJobTypes,
            },
        });
    };

    const renderSelectedCategories = () => {
        let chosenCategoriesTitles = [];
        for (let i = 0; i < categories.length; i++) {
            for (let j = 0; j < categories[i].categories.length; j++) {
                if (contextItems.searchItems.categories.includes(categories[i].categories[j].category_id)) {
                    chosenCategoriesTitles.push({
                        id: categories[i].categories[j].category_id,
                        title: categories[i].categories[j].category_title,
                    });
                }
            }
        }

        let selectedCategoriesContent = '';
        if (chosenCategoriesTitles.length) {
            selectedCategoriesContent = chosenCategoriesTitles.map((item, index) => (
                <div key={index} className="line-category-wrapper">
                    <div className="line-category-title">{item.title}</div>
                    <img
                        src={lineClose}
                        alt="line-close"
                        style={{ cursor: 'pointer' }}
                        onClick={() => refetchVacancies(item.id)}
                    />
                </div>
            ));
        }

        return selectedCategoriesContent;
    };

    return (
        <div id="job-section" className="job-section-block">
            <div className="jobssection-wrapper">
                <div className="jobssection-content">
                    <div className="title-wrapper">
                        <div className="title-top-wrapper">
                            <h2 className="title">
                                {t('header.jobsFound', { count: contextItems.vacancies[0]?.total })}
                            </h2>
                            {!!contextItems.searchItems.categories.length && !!contextItems.vacancies.length && (
                                <div className="clear-wrapper" onClick={() => refetchVacancies(0)}>
                                    <img src={clear} alt="clear" />
                                    {!isMobile && <p>{t('header.clear')}</p>}
                                </div>
                            )}
                            <button className="button filter-job-button" onClick={filterClick}>
                                <img src={filter} alt="filter" />
                                {!isMobile && <span className="button-title">{t('header.filter')}</span>}
                            </button>
                        </div>
                        <div className="title-bottom-wrapper">
                            {!!contextItems.searchItems.categories.length &&
                                !!contextItems.vacancies.length &&
                                renderSelectedCategories()}
                        </div>
                    </div>
                    <div className="sorting-wrapper">
                        <SortingItem field={'vacancy_title'} itemName={t('header.jobTitle')} />
                        {!isMobile && <SortingItem field={'address'} itemName={t('header.location')} />}
                    </div>
                </div>
                <JobItem
                    isMobile={isMobile}
                    foundJobs={contextItems.vacancies}
                    searchItems={contextItems.searchItems}
                />
            </div>
        </div>
    );
}
