import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './ErrorPage.css';
import { AppContext } from '../../context/appContext';
import Footer from '../footer/Footer';
import Header from '../header/Header';

export default function ErrorPage() {
    const { contextItems, setContextItems } = useContext(AppContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goHome = () => {
        setContextItems({
            ...contextItems,
            vacancies: [],
            showGreeting: true,
            searchItems: { categories: [], countryId: null, job_types: [] },
        });

        navigate('/');
    };
    return (
        <div className="app-content-wrapper white">
            <div className="error-page-wrapper">
                <Header />
                <div className="content-wrapper">
                    <h1 className="error-title">{t('errorPage.main')}</h1>
                    <p className="error-text">{t('errorPage.secondText')}</p>
                    <button className="button-primary error-button" onClick={goHome}>
                        {t('errorPage.back')}
                    </button>
                </div>

                <Footer />
            </div>
        </div>
    );
}
