import { useState } from 'react';

import './Dropdown.css';

export default function Dropdown({ onChoose, list, searchItem }) {
    const [searchText, setSearchText] = useState('');
    const [searchList, setSearchList] = useState(list);

    const searchTextInputHandler = (e) => {
        setSearchText(e.target.value);

        if (e.target.value === '') {
            setSearchList(list);
            return;
        }

        const newSearchList = list.filter((searchItem) => {
            return searchItem.country_title.toLowerCase().includes(e.target.value.toLowerCase());
        });

        setSearchList(newSearchList);
    };

    // const renderItems = () => {
    //     const rI = searchList.map((item) => {
    //         return (
    //             <p key={item.country_id} className="dropdown-item" onClick={() => onChoose(item.country_id)}>
    //                 {item.country_title}
    //             </p>
    //         );
    //     });
    //     console.log('RENDER ITEMs: ', rI);

    //     return rI;
    // };
    // move to context

    const branchesIds = [];

    searchList.forEach((item) => {
        if (item.branches) {
            item.branches.forEach((branch) => {
                branchesIds.push(branch.country_id);
            });
        }
    });

    const renderItems = () => {
        const countriesListMarkup = [];

        searchList.forEach((item) => {
            let renderItem = [];

            if (branchesIds.includes(item.country_id)) {
                return;
            }

            renderItem.push(
                <p key={item.country_id} className="dropdown-item" onClick={() => onChoose(item.country_id)}>
                    {item.country_title}
                </p>
            );

            if (item.branches) {
                item.branches.forEach((branch) => {
                    renderItem.push(
                        <p
                            key={branch.country_id}
                            className="dropdown-item"
                            onClick={() => onChoose(branch.country_id)}
                        >
                            {`- ${branch.country_title}`}
                        </p>
                    );
                });
            }

            countriesListMarkup.push(...renderItem);
        });

        return countriesListMarkup;
    };

    return (
        <div className="search-dropdown">
            <div className="input-wrapper">
                <input
                    id="search-input"
                    // type="text"
                    className="search-field"
                    placeholder="Search"
                    onInput={searchTextInputHandler}
                    value={searchText}
                    autoComplete="off"
                />
            </div>

            <div className="items-wrap">
                <div className="items-wrap-inner">{renderItems()}</div>
            </div>
        </div>
    );
}
